<template>
  <div class="forgotPassword">
    <LSection>
      <template #body>
        <form
          @submit.prevent="onSubmit"
          ref="form"
          class="mx-auto shadow-2xl p-6 rounded max-w-2xl"
        >
          <div class="flex flex-wrap p-6 w-100 mx-auto">
            <h1 class="text-xl text-purple-600 font-semibold">
              Forgot password?
            </h1>
            <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
              <label for="mail"
                >Please enter the email linked to your profile</label
              >
              <TextInput name="name" placeholder="Email adress"></TextInput>
            </div>
            <button @click="isSent = true" type="submit">Reset password</button>
            <label class="p-2" v-if="isSent">
              An email has been sent to your email adress.
            </label>
          </div>
        </form>
      </template>
    </LSection>
  </div>
</template>

<script>
import LSection from "@/components/layout/LSection.vue";
import TextInput from "@/components/forms/TextInput.vue";

export default {
  data() {
    return {
      isSent: false,
    };
  },
  components: {
    LSection,
    // TextArea,
    TextInput,
  },
  name: "ForgotPassword",
};
</script>

<style scoped></style>
